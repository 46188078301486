<template>
  <div class="login">
    <b-form @submit="onSubmit">
<!--      <h1 class="h3 mb-3 fw-normal">福彩</h1>-->

      <div >
<!--        <b-form-group-->
<!--                id="input-group-1"-->
<!--                label="用户名"-->
<!--                label-for="input-1"-->
<!--        >-->
        <b-form-input id="input-1" type="text" class="form-control" aria-required="true"  placeholder="用户名" required v-model="form.username">
        </b-form-input>
<!--        </b-form-group>-->

      </div>
      <div >
<!--        <b-form-group-->
<!--          label="密码"-->
<!--          label-for="floatingPassword"-->
<!--        >-->
        <b-form-input style="margin-top: 15px" type="password" class="form-control" required id="floatingPassword" placeholder="密码" v-model="form.password">
        </b-form-input>
<!--        </b-form-group>-->
      </div>

      <div >
<!--        <b-form-select class="form-control"-->
<!--                       style="margin-top: 15px"-->
<!--          id="input-3"-->
<!--          v-model="form.roleId"-->
<!--          :options="roleIds"-->
<!--          required-->
<!--        >-->
<!--        </b-form-select>-->
      </div>

      <button class="w-100 mt-3 btn btn-lg btn-primary" type="submit">登录</button>
    </b-form>
  </div>
</template>

<style>
.login {
  /*width: 100%;*/
  width: 330px;
  margin: 200px auto;
  padding: 15px;
}
</style>

<script>
import User from "@/model/User"
export default {
  data(){
    return {
      form: {
        username: '',
        password: '',
        roleId: 2
      },
      user: {},
      // roleIds: [
      //   // { value: null, text: '请输入角色' },
      // //   {
      // //   value: '1',
      // //     text: '管理员'
      // // },
      //   {
      //   value: '2',
      //     text: '渠道'
      // },
      // //   {
      // //   value: '3',
      // //     text: '财务'
      // // },
      // //   {
      // //   value: '4',
      // //     text: '销售'
      // // }
      // ],
    }
  },
  created() {
    console.log(this.$router.currentRoute.fullPath)
  },
  methods:{
    onSubmit:function (e) {
      e.preventDefault();
      User.login(this.form).then(response => {
        if (response.data.code === 0) {
          this.user = response.data.data;
          console.log(JSON.stringify(this.user));
          localStorage.setItem("token",this.user.token);
          localStorage.setItem('user', JSON.stringify(this.user));
          if(this.user.roles.indexOf(1) >= 0) {
            this.$router.replace({path: '/data'});
          } else if(this.user.roles.indexOf(2) >= 0) {
            this.$router.replace({path:'/console/stat'})
          } else if(this.user.roles.indexOf(3) >= 0) {
            this.$router.replace({path:'/finance'})
          } else if(this.user.roles.indexOf(4) >= 0) {
            console.log('销售')
          }
          // this.$router.push({path:'/data'})
        } else {
          // console.log('提交失败，请稍后再试'+response.data.msg);
          this.$bvToast.toast(response.data.msg);
          this.loading = false;

        }
      });
    }
  },


}
</script>
